import React from 'react';
import './App.css';
import { Body } from './components/Body';
import { Footer } from './components/Footer';
import { Title } from './components/Title'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Title/>
      </header>
      <body>
        <Body/>
      </body>
      <footer>
        <Footer/>
      </footer>
    </div>
  );
}

export default App;
