import React from 'react'
import { Box, HStack, Heading, Icon, Link } from '@chakra-ui/react'
import { YouTubeFrame } from './socials/YouTubeFrame'
import { TwitchFrame } from './socials/TwitchFrame'
import { SiRedbubble } from 'react-icons/si';

export function Body() {
  return (
    <Box id='body' backgroundColor='#0C1B31' color='white'>
      <Box paddingBottom='10pt' width='fit-content' margin='auto'>
        <Link href='https://www.redbubble.com/people/TheSpaceCoder/shop'>
          <HStack>
            <Heading fontFamily='Electrolize'>Merchandise</Heading>
            <Icon boxSize='20pt' as={SiRedbubble} />
          </HStack>

        </Link>
      </Box>
      <YouTubeFrame />
      <TwitchFrame />

    </Box>
  )
}