import React from 'react'
import { Box, Icon, HStack, VStack, Link, Divider } from '@chakra-ui/react';
import { AiFillYoutube, AiFillTwitterCircle, AiFillInstagram } from 'react-icons/ai'
import { FaTwitch, FaDiscord, FaFlickr } from 'react-icons/fa';

export function Footer() {
  return (
    <Box id='footer' marginTop='50pt'>
      <VStack>
        <HStack>
          <Link href='https://www.youtube.com/c/thespacecoder'>
            <Icon boxSize='20pt' cursor='pointer' as={AiFillYoutube} />
          </Link>
          <Link href='https://twitter.com/TheSpaceCoder1'>
            <Icon boxSize='20pt' cursor='pointer' as={AiFillTwitterCircle} />
          </Link>
          <Link href='https://www.twitch.tv/thespacecoder'>
            <Icon boxSize='20pt' cursor='pointer' as={FaTwitch} />
          </Link>
          <Link href='https://www.instagram.com/thespacecoder2.0/'>
            <Icon boxSize='20pt' cursor='pointer' as={AiFillInstagram} />
          </Link>
          <Link href='https://discord.com/invite/cY9gx4E'>
            <Icon boxSize='20pt' cursor='pointer' as={FaDiscord} />
          </Link>
          <Link href='https://www.flickr.com/photos/162203426@N06'>
            <Icon boxSize='20pt' cursor='pointer' as={FaFlickr} />
          </Link>
        </HStack>
        <Divider width='60vw'/>
        <HStack>
          <Box width='fit-content' margin='auto'>©TheSpaceCoder 2018 - 2021 thespacecoder.business@gmail.com</Box>
        </HStack>
      </VStack>
    </Box>
  )
}