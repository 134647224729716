import { Heading, Box, HStack, Image, VStack, Divider } from '@chakra-ui/react';
import React from 'react';
import logo from '../logo.png';

export function Title() {
  return (
    <Box>
      <HStack>
        <Image src={logo} boxSize='xs' fit='contain' paddingRight='10pt'/>
        <Divider orientation='vertical' height='140pt' paddingRight='10pt'/>
        <VStack align='baseline'>
          <Heading fontWeight='light' as='h1' size='xl'>Welcome!</Heading>
          <Heading fontWeight='light' fontFamily='Exo' as='h2' size='md'>My website is still under construction</Heading>
          <Heading fontWeight='light' fontFamily='Exo' as='h2' size='xs'>(Mostly because I have the attention span of a goldfish with ADHD)</Heading>
          <Heading fontWeight='light' fontFamily='Exo' as='h2' size='md'>Feel free to check out my other platforms in the meantime!</Heading>
        </VStack>
      </HStack>
    </Box>
  )
}