import React from 'react'
import { Heading, Box, HStack } from '@chakra-ui/react'

export function YouTubeFrame() {
  return (
    <Box width='fit-content' margin='auto' padding='20pt'>
      <Heading fontFamily='Electrolize'>Latest YouTube video</Heading>
      <HStack>
        <Box backgroundColor='#0C1B31'>
          <iframe width="560" height="315" src="https://www.youtube.com/embed/?list=PLrbO46Ipr7afcP3ft41a_-h_XpmFUooRu"
            title="TheSpaceCoder@YouTube" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
          </iframe>
        </Box>

      </HStack>

    </Box>
  )
}