import React from 'react'
import { Heading, Box, HStack } from '@chakra-ui/react'

export function TwitchFrame() {
  return (
    <Box width='fit-content' margin='auto'>
      <Heading fontFamily='Electrolize'>Current live stream</Heading>
      <HStack>
        <Box backgroundColor='#0C1B31'>
          <iframe src={`https://player.twitch.tv/?channel=thespacecoder&parent=www.thespacecoder.space&parent=thespacecoder.space&parent=tsc-website.netlify.app`}
            frameBorder="0" allowFullScreen scrolling="no" height="315" width="560" title='Twitch'></iframe>
        </Box>
      </HStack>
    </Box>
  )
}